<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Categories
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Search..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="newClick"
        :disabled="!user.can('category.create')"
      >
        <v-icon
          small
          left
        >fas fa-folder-plus</v-icon>
        New
      </v-btn>
    </v-row>

    <v-data-table
      :class="{ 'clickable': user.can('category.update') }"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.is_disabled="{ item }">
        <v-icon :color="item.is_disabled ? 'error' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>
    
    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="primary" dark flat short class="px-2">
          <v-toolbar-title>Category</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.name"
              :error-messages="errors.name"
              label="Name"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.code"
              :error-messages="errors.code"
              label="Code"
            ></v-text-field>

            <v-select
              outlined
              clearable
              clear-icon="fas fa-times"
              class="mb-2"
              v-model="model.parent_id"
              :error-messages="errors.parent_id"
              item-text="name"
              item-value="id"
              :items="parentItems"
              label="Parent"
            ></v-select>

            <v-checkbox
              v-model="model.is_disabled"
              :error-messages="errors.is_disabled"
              label="Disabled"
              :false-value="0"
              :true-value="1"
            ></v-checkbox>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-btn
            color="error"
            class=""
            large
            outlined
            depressed
            @click="$refs.deleteCategoryConfirmDialog.show()"
            :disabled="!!(model.product_count || model.children_count)"
          >Delete</v-btn>
          <span class="px-3 counter">products: {{ model.product_count }} | children: {{ model.children_count }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Cancel</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Save</v-btn>
        </v-card-actions>

        <ConfirmDialog
          ref="deleteCategoryConfirmDialog"
          @confirm="deleteCategory()"
          title="Confirm"
          message="This category will be deleted. Are you sure?"
          labelConfirm="OK"
          labelCancel="Cancel"
        ></ConfirmDialog>


      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')
    const { user } = api

    const parentCollection = api.collection('categories')

    const { items: parentItems } = parentCollection.all()

    const itemCollection = api.collection('categories', {
      params: {
        expand: 'parent',
      },
      filter: {
        query: 'query:name',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = itemCollection

    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'Code', value: 'code' },
      { text: 'Parent', value: 'parent.name' },
      { text: 'Disabled', value: 'is_disabled', align: 'center' },
    ]

    const dialog = ref(false)

    const itemModel = api.model('categories/:id', {
      params: {
        expand:'product_count,children_count',
      },
    })
    const { attributes: model, errors, isBusy } = itemModel

    loader.bindRef(isBusy)

    function newClick() {
      itemModel.clear().setAttributes({ status: 9 })
      dialog.value = true
    }

    function rowClick(item) {
      if (user.can('category.update')) {
        itemModel.find(item.id).then(() => {
          dialog.value = true
        })
      }
    }

    function saveClick() {
      itemModel.save().then(() => {
        parentCollection.all()
        itemCollection.search()
        dialog.value = false
      })
    }

    function deleteCategory() {
      itemModel.delete().then(() => {
        parentCollection.all()
        itemCollection.search()
        dialog.value = false
      })
    }

    const searchKeyUp = itemCollection.debounceSearch(300)

    return {
      user: api.user,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      parentItems,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteCategory,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table.clickable table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

.counter {
  font-size: 0.75rem;
  font-weight: 300;
}

</style>
